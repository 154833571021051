import React from "react"
import styled, { css, ThemeProvider } from "styled-components"

import {
  Link,
  UnstyledLink,
  Section,
  SectionHeading,
  SectionSubheading,
  ContactContainer,
  ContactInformation,
  ContactKey,
  ContactValue,
  CallToAction,
} from "./shared"
import { narrowNoBreakSpace } from "../util/typography"
import { contactTheme } from "./theme"
import directionsMapLindlar from "../images/karte-lindlar.svg"
import directionsMapCologne from "../images/karte-koeln.svg"

const columnHeadingCss = css`
  font-size: 1.6rem;
`

const CallHeading = styled(SectionSubheading)`
  grid-area: call-heading;
  ${columnHeadingCss}
`

const PhoneNumber = styled.div`
  grid-area: phone-number;
`

const PhoneExtensionsLink = styled(CallToAction)`
  grid-area: phone-extensions-link;
  margin-top: 1em;
  justify-self: start;
  align-self: start;

  @media only screen and (max-width: 666px) {
    justify-self: stretch;
  }
  @media only screen and (max-width: 480px) {
    justify-self: start;
  }
`

const WriteHeading = styled(SectionSubheading)`
  grid-area: write-heading;
  ${columnHeadingCss}
`

const EmailAddress = styled.div`
  grid-area: email-address;
`

const ContactLink = styled(CallToAction)`
  grid-area: contact-link;
  margin-top: 1em;
  justify-self: start;
  align-self: start;

  @media only screen and (max-width: 666px) {
    justify-self: stretch;
  }
  @media only screen and (max-width: 480px) {
    justify-self: start;
  }
`

const HoursHeading = styled(SectionSubheading)`
  grid-area: hours-heading;
  ${columnHeadingCss}
`

const Hours = styled.div`
  grid-area: hours;
`

const Branch1Map = styled.div`
  grid-area: branch1-map;

  @media only screen and (min-width: 666px) {
    align-self: end;
  }
`

const Branch2Map = styled.div`
  grid-area: branch2-map;

  @media only screen and (min-width: 666px) {
    align-self: end;
  }
`

const Branch1Address = styled.div`
  grid-area: branch1-address;
`

const Branch2Address = styled.div`
  grid-area: branch2-address;
`

export const ContactGrid = styled.div`
  display: grid;
  column-gap: 1em;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: ${props =>
    props.$withBranches
      ? `
    "call-heading branch1-map branch2-map"
    "phone-number branch1-map branch2-map"
    "phone-extensions-link branch1-map branch2-map"
    "write-heading branch1-map branch2-map"
    "email-address branch1-map branch2-map"
    "contact-link branch1-map branch2-map"
    "hours-heading branch1-address branch2-address"
    "hours branch1-address branch2-address"
    `
      : `
    "call-heading write-heading hours-heading"
    "phone-number email-address hours"
    "phone-extensions-link contact-link ."
    `};

  ${Branch1Map}, ${Branch2Map}, ${Branch1Address}, ${Branch2Address} {
    display: ${props => (props.$withBranches ? `unset` : `none`)};
  }

  @media only screen and (max-width: 666px) {
    display: block;
    max-width: 80%;
  }

  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`

const MapImage = styled.img`
  margin-top: 1em;
  width: 100%;
  background-color: #ffffff;
`

const Address = styled.address`
  font-style: normal;
`

const Contact = ({ withBranches }) => {
  return (
    <ThemeProvider theme={contactTheme}>
      <ContactContainer>
        <Section style={{ paddingBottom: "2em" }}>
          <SectionHeading>Kontakt</SectionHeading>
          <ContactGrid $withBranches={withBranches}>
            <CallHeading>Rufen Sie uns an</CallHeading>
            <PhoneNumber>
              <UnstyledLink to="tel:+4922669018900">
                02266{narrowNoBreakSpace}/{narrowNoBreakSpace}901890
                {narrowNoBreakSpace}-{narrowNoBreakSpace}0
              </UnstyledLink>
            </PhoneNumber>
            <PhoneExtensionsLink to="/team">
              direkte Durchwahl
            </PhoneExtensionsLink>

            <WriteHeading>Schreiben Sie uns</WriteHeading>
            <EmailAddress>
              <Link to="mailto:info@holzbauplanung.de">
                info@holzbauplanung.de
              </Link>
            </EmailAddress>
            <ContactLink to="/kontakt">Kontaktseite</ContactLink>

            <HoursHeading>Bürozeiten</HoursHeading>
            <Hours>
              <ContactInformation>
                <ContactKey>Mo.-Do.</ContactKey>
                <ContactValue>08:00-16:00</ContactValue>

                <ContactKey>Fr.</ContactKey>
                <ContactValue>08:00-13:00</ContactValue>
              </ContactInformation>
            </Hours>

            <Branch1Map>
              <MapImage src={directionsMapLindlar} alt="Karte Lindlar" />
            </Branch1Map>
            <Branch1Address>
              <SectionSubheading>Niederlassung Lindlar</SectionSubheading>
              <Address>
                Sülztalstraße 84
                <br />
                51789 Lindlar-Hartegasse
              </Address>
            </Branch1Address>

            <Branch2Map>
              <MapImage src={directionsMapCologne} alt="Karte Köln" />
            </Branch2Map>
            <Branch2Address>
              <SectionSubheading>Niederlassung Köln</SectionSubheading>
              <Address>
                Klingelpütz 22
                <br />
                50670 Köln
              </Address>
            </Branch2Address>
          </ContactGrid>
        </Section>
      </ContactContainer>
    </ThemeProvider>
  )
}

export default Contact
