import React from "react"
import Lottie from "react-lottie-wrapper"
import { useIntersection } from "react-use"

import architectsData from "./architects.json"
import carpentryData from "./carpentry.json"
import cloudData from "./cloud.json"
import cloudWhiteData from "./cloud-white.json"
import cooperationData from "./cooperation.json"
import dateArrangementData from "./dateArrangement.json"
import digitalData from "./digital.json"
import houseData from "./house.json"
import houseWhiteData from "./house-white.json"
import inquiryData from "./inquiry.json"
import methodData from "./method.json"
import modelData from "./model.json"
import modelWhiteData from "./model-white.json"
import treeData from "./tree.json"
import volumeData from "./volume.json"
import volumeWhiteData from "./volume-white.json"
import workshopData from "./workshop.json"

// TODO: to util
const useHasBeenInView = () => {
  const intersectionRef = React.useRef(null)

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  })

  const [hasBeenInView, setHasBeenInView] = React.useState(false)
  if (!hasBeenInView && intersection && intersection.intersectionRatio) {
    setHasBeenInView(true)
  }

  return { intersectionRef, hasBeenInView }
}

const Illustration = ({ animationData, height = 100 }) => {
  const { intersectionRef, hasBeenInView } = useHasBeenInView()

  const isPaused = !hasBeenInView

  return (
    <div ref={intersectionRef}>
      <Lottie
        height={height}
        isPaused={isPaused}
        options={{
          animationData: animationData,
          rendererSettings: {},
          loop: false,
          autoPlay: false,
        }}
        // default values would make it keyboard-focusable
        role="presentation"
        aria-label={null}
        tabIndex={-1}
      />
    </div>
  )
}

export const Architects = props => (
  <Illustration animationData={architectsData} {...props} />
)
export const Carpentry = props => (
  <Illustration animationData={carpentryData} {...props} />
)
export const Cloud = props => (
  <Illustration animationData={cloudData} {...props} />
)
export const CloudWhite = props => (
  <Illustration animationData={cloudWhiteData} {...props} />
)
export const Cooperation = props => (
  <Illustration animationData={cooperationData} {...props} />
)
export const DateArrangement = props => (
  <Illustration animationData={dateArrangementData} {...props} />
)
export const Digital = props => (
  <Illustration animationData={digitalData} {...props} />
)
export const House = props => (
  <Illustration animationData={houseData} {...props} />
)
export const HouseWhite = props => (
  <Illustration animationData={houseWhiteData} {...props} />
)
export const Inquiry = props => (
  <Illustration animationData={inquiryData} {...props} />
)
export const Method = props => (
  <Illustration animationData={methodData} {...props} />
)
export const Model = props => (
  <Illustration animationData={modelData} {...props} />
)
export const ModelWhite = props => (
  <Illustration animationData={modelWhiteData} {...props} />
)
export const Tree = props => (
  <Illustration animationData={treeData} {...props} />
)
export const VolumeWhite = props => (
  <Illustration animationData={volumeWhiteData} {...props} />
)
export const Volume = props => (
  <Illustration animationData={volumeData} {...props} />
)
export const Workshop = props => (
  <Illustration animationData={workshopData} {...props} />
)
